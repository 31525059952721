<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            :fullscreen="false"
            persistent
            max-width="1000px"
        >            
            <v-card>
                <v-toolbar
                    class="sticky-toolbar"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>{{ myInvoice.title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn                                        
                            dark
                            icon
                            @click="downloadInvoice"
                        >
                            <v-icon
                                dark
                            >
                                mdi-download
                            </v-icon>
                        </v-btn>
                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_invoice" v-slot="{  }">      
                    <v-form class="mb-16 mt-12" @submit.prevent="addInvoice" >
                        <fieldset :disabled="true">   
                            <v-row>               
                            <v-col cols="12" md="4">
                                <validation-provider v-slot="{ errors }" vid="title" name="title" rules="required">
                                    <v-text-field :error-messages="errors" label="Titulo" v-model="myInvoice.title"></v-text-field>
                                </validation-provider>
                            </v-col>               
                            <v-col cols="12" md="8" class="text-right">                                
                                <label class="text-h6 text-right">Estado:</label>
                                <Tooltip
                                    v-if="myInvoice.paid"
                                    class="my-4 mr-4"
                                    color="green"
                                    text="Paga"
                                    top
                                    >
                                    Pago
                                    <v-icon
                                    color="success"
                                    >mdi-check</v-icon>
                                    </Tooltip>
                                    <Tooltip
                                    v-else
                                    class="my-4 mr-4"
                                    color="red"
                                    text="Por pagar"
                                    top
                                    >
                                    Por pagar
                                    <v-icon
                                    color="red"
                                    >mdi-alert-circle-outline</v-icon>
                                    </Tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                <v-textarea
                                name="input-7-1"
                                v-model="myInvoice.description"
                                :error-messages="errors"
                                label="Descriçao"
                                ></v-textarea>
                            </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                            <validation-provider v-slot="{ errors }" vid="total" name="total" rules="required">
                                <v-text-field :error-messages="errors" label="Total" v-model="myInvoice.total" prepend-icon="mdi-currency-eur"></v-text-field>
                            </validation-provider>
                            </v-col>
                            <v-col cols="6">
                            <validation-provider v-slot="{ errors }" vid="expires_at" name="expires_at" rules="required">
                                <v-text-field :error-messages="errors" label="Expira a" v-model="myInvoice.expires_at"></v-text-field>
                            </validation-provider>
                            </v-col>
                        </v-row>   
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<style>
.sticky-toolbar{
    position: sticky; 
    top: 0; 
    z-index: 999;
}
</style>
<script>
import Invoices from "@/api/Invoices.js"
import Tooltip from "@/components/ui/Tooltip"

export default {
    components:{
        Tooltip
    },
    props:{
        selectedInvoice:{
            default: null
        },
        tenant:{
            default: null
        }
    },
    data(){
        return{
            developers: [],
            reloadComments: 0,
            dialog: false,
            loading: false,         
            success: false,             
            myInvoice:{
                file: null,
                total: null,
                paid: false,
                description: '',
                expires_at: '',
                id: '',
                title: ''
            }
        }
    },
    beforeCreate(){
        
    },
    watch:{
        selectedInvoice:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                
                val.expires_at = val.expires_at.split(' ')[0]
                this.myInvoice.description = val.description
                this.myInvoice.total = val.total
                this.myInvoice.expires_at = val.expires_at
                this.myInvoice.paid = val.paid
                this.myInvoice.file = val.file
                this.myInvoice.path = val.path
                this.myInvoice.id = val.id
                this.myInvoice.title = val.title
            }
        }
    },
    methods:{
        downloadInvoice(){
            Invoices['download'](this.myInvoice.id, this.tenant).then((response) => {
                let documentName =  this.myInvoice.title;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.$refs.InvoiceName.closeDialog();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        formatData(d){
            return d.split('T')[0] + ' ' + d.split('T')[1].split('.')[0]
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadInvoices')
        },
        openDialog(){
            this.dialog = true
        },
    }
}
</script>