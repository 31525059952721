<template>
  <v-app>
    <div class="bg"></div>
    <div class="absolute">      
      <v-img
          class="d-none d-sm-flex ml-12 mt-12"
          lazy-src="@/assets/logo_dream.png"
          src="@/assets/logo_dream.png"
          max-width="350"
      ></v-img> 
      
      <v-img
          class="d-sm-none mt-6 ml-6 mb-n10"
          lazy-src="@/assets/logo_dream.png"
          src="@/assets/logo_dream.png"
          max-width="350"
      ></v-img>
    </div>
    <v-main>
      <v-container fill-height>
        <!-- DESKTOP VERSION -->        
        <v-row class="d-none d-sm-flex">
          <v-row class="div_login">
            <v-col cols="12" md="6">
              <div class="text-right mt-12">
                <p class="orange-app text-h3 font-weight-black">Área reservada clientes</p>
                <p class="text-h6 font-weight-light">Acompanhamento de projetos</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <validation-observer
                  ref="login"
                  v-slot="{ invalid, dirty }"
                >
                  <v-form @submit.prevent="submit">
                    <v-row>
                      <v-col>
                        <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="username" label="Email\Username" prepend-inner-icon="mdi-account" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-btn
                          depressed
                          color="#F86F15"
                          class="mr-4 text-white"
                          type="submit"
                          :disabled="invalid && !dirty"
                          large
                          block
                        >
                          Login
                        </v-btn>
                      </v-col>
                      <v-col cols="12" :md=" !$msal.isAuthenticated() ? 12 : 10">
                        <v-btn
                          block
                          class="mt-4"
                          outlined
                          small
                          @click="microsoftLogin"
                        >
                          <v-icon>mdi-microsoft</v-icon>
                          {{ $msal.isAuthenticated() ? user.userName : 'Usar conta Microsoft' }}
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          block
                          class="mt-4"
                          outlined
                          small
                          @click="$msal.signOut()"
                          v-if="$msal.isAuthenticated()"
                        >
                          <v-icon>mdi-logout</v-icon>
                        </v-btn>
                        </v-col>
                        <v-col cols="12">


                        <v-btn
                          text
                          color="#F86F15"
                          class="mt-4"
                          type="button"
                          block
                          @click="reset_password.opened = !reset_password.opened"
                        >
                          Esqueci-me da password
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <!-- DESKTOP VERSION END-->

        <!-- MOBILE VERSION -->
        <v-row class="d-sm-none">
          <v-row class="div_login_mobile">
            <v-col cols="12" md="6">
              <div class="text-center mt-12">
                <p class="orange-app text-h3 font-weight-black">Área reservada</p>
                <p class="text-h6 font-weight-light">Acompanhamento de projetos</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="0">
                <validation-observer
                  ref="login"
                  v-slot="{ invalid, dirty }"
                >
                  <v-form @submit.prevent="submit">
                    <v-row>
                      <v-col>
                        <validation-provider name="Email\Username" vid="username" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="username" label="Email\Username" prepend-inner-icon="mdi-account" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field v-model="password" type="password" label="Password" prepend-inner-icon="mdi-lock" :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          depressed
                          color="#F86F15"
                          class="mr-4 text-white"
                          type="submit"
                          :disabled="invalid && !dirty"
                          large
                          block
                        >
                          Login
                        </v-btn>
                        </v-col>
                        
                      <v-col cols="12" :md=" !$msal.isAuthenticated() ? 12 : 10">
                        <v-btn
                          block
                          class="mt-4"
                          outlined
                          small
                          @click="microsoftLogin"
                        >
                          <v-icon>mdi-microsoft</v-icon>
                          {{ $msal.isAuthenticated() ? user.userName : 'Usar conta Microsoft' }}
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn
                          block
                          class="mt-4"
                          outlined
                          small
                          @click="$msal.signOut()"
                          v-if="$msal.isAuthenticated()"
                        >
                          <v-icon>mdi-logout</v-icon>
                        </v-btn>
                        </v-col>
                        <v-col>

                        <v-btn
                          text
                          color="#F86F15"
                          class="mt-4"
                          type="button"
                          block
                          @click="reset_password.opened = !reset_password.opened"
                        >
                          Esqueci-me da password
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <!-- MOBILE VERSION END -->
        </v-container>
    </v-main>
    <v-dialog
        v-model="reset_password.opened"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5 orange-app">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-if="!reset_password.reseted" ref="reset" v-slot="{ invalid }">
              <v-form @submit.prevent="reset">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                        <v-text-field
                          v-model="reset_password.email"
                          prepend-inner-icon="mdi-at"
                          label="Email"
                          type="email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="#F86F15" class="mr-5 ml-5 mb-5 text-white" type="submit">
                        Enviar email
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
            <v-container v-else>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">Email enviado!</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#F86F15"
              text
              @click="reset_password.opened = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-app>
</template>
<style>
.orange-app{
  color: #F86F15 !important;
}
.text-white{
  color: white !important;
}
.div_login{
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.div_login_mobile{
  opacity: 0.8;
}
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background: url( '../assets/dreamdeal1.png') no-repeat center center;
    background-size: cover;
  }

</style>
<script>
import User from "@/api/User.js"
import { msalMixin } from 'vue-msal'; 

export default {
  components: {
  },
  mixins: [msalMixin],
  data: () => ({
    username: "",
    password: "",
    reset_password: {
      opened: false,
      email: "",
      reseted: false,
    }
  }),
  beforeCreate(){
    document.title = "Atlas";

    if(localStorage['auth'] == 1){
      this.$router.push('/client');
    }
  },
  mounted(){
    let cookieLogin = this.$cookies.get('login-try');

    if(cookieLogin){
      if(this.$msal.isAuthenticated()){
        User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
            .then(({data}) => {

                if(data.two_factor){
                  this.$router.push('/2fa');

                  return;
                }

                localStorage['auth'] = 1;

                this.$cookies.remove('login-try');

                this.$router.push('/client');
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }
          });
        }
    }
  },
  methods:{
    async microsoftLogin(){
      /*var loginRequest = {
        account: null,
       scopes: ["user.read", "mail.send"] // optional Array<string>
   };
  

      await msalInstance.loginPopup(loginRequest)
        .then(response => {
            // handle response
            console.log("OLHA A RESPOSTA")
            console.log(response)
        })
        .catch(err => {
            // handle error
            console.log("OLHA O ERRO")
            console.log(err)
        });*/


      this.$cookies.set('login-try', true);

      if(this.$msal.isAuthenticated()){
        User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
            .then(({data}) => {

                if(data.two_factor){
                  this.$router.push('/2fa');

                  return;
                }

                localStorage['auth'] = 1;

                this.$cookies.remove('login-try');

                this.$router.push('/client');
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }
          });
        }else{
          this.$msal.signIn()
        }
    },
    submit () {
        this.$refs.login.validate().then((valid) => {
          if (!valid) {
            return;
          }

          User.logout()
            .then(() => {
              this.login();
            }).catch(() => {
              this.login();
            })
        });
      },
      login(){
        this.password = this.$encryptValue(this.password)
        User.login(this.username, this.password)
          .then(({data}) => {
              if(data.two_factor){
                this.$router.push('/2fa');

                return;
              }

              localStorage['auth'] = 1;

              this.$router.push('/client');
          }).catch(err => {

            if(err.response.status == 422) {

              this.$refs.login.setErrors(err.response.data.errors);
              
              return;
            }
        });
      },
      reset () {
        this.$refs.reset.validate().then((valid) => {
          if (!valid) {
            return;
          }

          User.reset(this.reset_password.email)
            .then(() => {

                this.reset_password.reseted = true;
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.reset.setErrors(err.response.data.errors);
                
                return;
              }

              this.reset_password.reseted = false;

          });
        });
      },
  },
  computed: {
      user() {
        let user = null;
        if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
          user = {
            ...this.msal.user,
            profile: {}
          }
          if (this.msal.graph && this.msal.graph.profile) {
              user.profile = this.msal.graph.profile
          }
        }
        
        return user;
      }
  }
};
</script>
