<template>
    <div>
        <div style="position:relative">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16 elevation-4"
          locale="pt-pt"
        >
          <template v-slot:top>
            <v-toolbar flat color="primary">
              <v-toolbar-title>
                <h1 class="white--text">
                  <span>
                    <v-icon x-large dense color="white" class="mr-2">mdi-briefcase</v-icon>
                  </span>
                  <span>Projetos</span>
                </h1>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-speed-dial
                  v-model="speedDial"
                  :open-on-hover="true"
                >
                  <template v-slot:activator>
                    <v-tooltip right color="#948F8F">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs" v-on="on"
                          v-model="speedDial"
                          color="primary"
                          elevation="0"
                          dark
                          small
                          fab                          
                        >
                          <v-icon v-if="speedDial">
                            mdi-close
                          </v-icon>
                          <v-icon v-else>
                            mdi-dots-vertical
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Opções</span>
                    </v-tooltip>
                  </template>
                </v-speed-dial>
            </v-toolbar>
          </template>
          <template v-slot:item.started_at="{item}">
              {{ formatData(item.started_at) }}              
          </template>

          <template v-slot:item.project_deadline="{item}">
              {{ formatData(item.project_deadline) }}              
          </template>
          

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewProject(item)"
            />
          </template>
        </v-data-table>
      </div>
      <Project ref="Project" :selectedProject="selectedProject"/>
    </div>
</template>
<script>
import IconView from '@/components/ui/IconView.vue';
import Projects from "@/api/Projects.js"
//import ClientUsers from "@/api/ClientUsers.js"
//import Tooltip from "@/components/ui/Tooltip"
//import AddProject from "@/components/clients/projects/AddProject"
import Project from "@/components/projects/Project"

export default {
  components: {
    IconView,
    Project
  },
  props:['tenant'],
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchProjects()}, 10);
      },
      deep: true,
    },
  },
  data: () => ({
    selectedProject: null,
    headers: [
      {
        text: 'Nome',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Descrição',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Início',
        sortable: false,
        value: 'started_at',
      },
      {
        text: 'Deadline',
        sortable: false,
        value: 'project_deadline',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      paid:false,
    },
    projects:[]
  }),
  mounted(){
    this.fillBaseData();

    console.log(this.$root.session)
  },
  methods:{
    openDialog(){
      this.$refs.AddProject.openDialog()
    },
    showWarningIcon(dt){
      var fDt = new Date(dt);
      var today = new Date();
      var Difference_In_Time = fDt.getTime() - today.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if(Difference_In_Days <= 7)
        return true
      return false
    },
    formatData(dt){
      if(dt == null)
        return '-'

      return dt.split(' ')[0]
    },
    fillBaseData() {

      if(localStorage["clientsprojects-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["clientsprojects-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchProjects(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["clientsprojects-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Projects.getProjects(request, this.tenant).then(response => {
        
        let data = response.data;

        this.total = data.total;
          this.items = data.data.map((item) => {

          this.searching = false;

          return item;
          });
        

        this.loading = false;

      });
    },
    resetProjects()
    {
      this.filter = {};

      this.searchProjects();
    },
    viewProject(item){
      this.selectedProject = item
      this.$refs.Project.openDialog()
    },
  },
  computed: {
    
  }
};
</script>
