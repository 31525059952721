import BaseApi from "./BaseApi";

export default class Dashboard extends BaseApi{
    construct() {}

    static async get(params) {
        return await Dashboard.getAxiosInstance().get(Dashboard.getFullApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/atlasdashboard/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/atlasdashboard/";
    }

}