<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            :fullscreen="false"
            persistent
            max-width="1000px"
        >            
            <v-card>
                <v-toolbar
                    class="sticky-toolbar"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>{{ myPackage.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_package" v-slot="{  }">      
                    <v-form class="mb-16 mt-12" @submit.prevent="addPackage" >
                        <fieldset :disabled="true">   
                            <v-row>
                            <v-col cols="12" md="3">
                                <validation-provider v-slot="{ errors }" vid="name" name="name" rules="required">
                                    <v-text-field
                                    v-model="myPackage.name"
                                    :error-messages="errors"
                                    label="Nome"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>                                                        
                            <v-col cols="12" md="3">                                
                                <validation-provider v-slot="{ errors }" vid="price" name="price" rules="required">                                    
                                    <v-text-field
                                        v-model="myPackage.price"
                                        :error-messages="errors"
                                        label="Preço"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>                               
                            <v-col cols="12" md="6" class="text-right" v-if="myPackage.discount > 0">    
                                <label class="text-h6 text-right">Desconto aplicado:</label>
                                <p class="text-h6">{{ myPackage.discount }}<v-icon x-large color="success">mdi-sale</v-icon></p>
                            </v-col>         
                            <v-col cols="12" md="6" class="text-right" v-else>    
                                <label class="text-h6 text-right">Sem desconto</label>
                            </v-col>         
                        </v-row>
                        <v-row class="mt-8">
                            <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                <v-textarea
                                name="input-7-1"
                                v-model="myPackage.description"
                                :error-messages="errors"
                                label="Descriçao"
                                ></v-textarea>
                            </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-col cols="12" md="2">     
                                <v-row>
                                    <v-col>
                                        <label>Total horas:</label>
                                        <p class="text-left primary--text text-h6">                                            
                                            <label>{{ myPackage.hours }} horas</label>
                                        </p>
                                    </v-col>
                                </v-row>        
                            </v-col>
                            <v-col cols="12" md="3">     
                                <v-row>
                                    <v-col>
                                        <label>Horas gastas:</label>
                                        <v-progress-circular                                    
                                            class="mt-4"
                                            :rotate="-90"
                                            :size="150"
                                            :width="25"
                                            :value="hoursSpent(myPackage)"
                                            color="primary"
                                        >
                                            {{ hoursSpent(myPackage) }} %
                                        </v-progress-circular>      
                                    </v-col>
                                </v-row>        
                            </v-col>
                        </v-row>
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
            <v-card>
                <v-toolbar
                    dark
                    color="#948F8F"
                >                    
                    <v-toolbar-title>Comentários: </v-toolbar-title>                    
                </v-toolbar>
                <v-container v-if="myPackage.comments != null">
                    <p class="text-center" v-if="myPackage.comments.length == 0">Sem comentários</p>
                    <div :key="reloadComments">
                    <v-timeline dense v-for="c in myPackage.comments" :key="c.id">
                        <v-timeline-item size="large" v-if="c.landlord_user == null">
                            <template v-slot:icon>
                                <v-avatar><v-icon color="white">mdi-account</v-icon></v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span>{{ c.tenant_user.name }}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>{{formatData(c.created_at)}}</v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item size="large" v-else>
                            <template v-slot:icon>
                                <v-avatar>
                                    <v-icon color="white" v-if="c.burned_hours == null">mdi-account</v-icon>
                                    <v-icon color="white" v-else>mdi-fire-circle</v-icon>
                                </v-avatar>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>
                                    <v-row v-if="c.burned_hours != null">
                                        <v-col>
                                            <p class="font-weight-bold">Horas queimadas: {{c.burned_hours}}</p>
                                        </v-col>
                                    </v-row>
                                    <strong>{{ c.landlord_user.name }}</strong> at {{formatData(c.created_at)}}
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </div>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<style>
.sticky-toolbar{
    position: sticky; 
    top: 0; 
    z-index: 999;
}
</style>
<script>

export default {
    props:{
        selectedPackage:{
            default: null
        }
    },
    data(){
        return{
            developers: [],
            reloadComments: 0,
            commentObject: {
                comment: '',
                burned_hours: '',
                isBurnHours: false,
                id_package: null
            },
            dialog: false,
            loading: false,         
            success: false, 
            myPackage:{
                name: '',
                description: '',
                hours: '',
                hours_spent: '',
                id_package: '',
                id: '',
                price: '',
                discount: null,
                comments: null
            },
        }
    },
    beforeCreate(){
        
    },
    watch:{
        selectedPackage:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                this.myPackage.id = val.id
                this.myPackage.name = val.name
                this.myPackage.description = val.description
                this.myPackage.hours = val.hours
                this.myPackage.hours_spent = val.hours_spent
                this.myPackage.id_package = val.id_package
                this.myPackage.comments = val.comments
                this.myPackage.price = val.price
                this.myPackage.discount = val.discount
            }
        }
    },
    methods:{
        hoursSpent(data){
            let total_horas = data.hours
            let total_gastas = data.hours_spent
            return Math.round((total_gastas * 100) / total_horas)
        },
        startedAtChanged(t){
            this.myPackage.started_at = t
        },
        formatData(d){
            return d.split('T')[0] + ' ' + d.split('T')[1].split('.')[0]
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadPackages')
        },
        openDialog(){
            this.dialog = true
        },
    }
}
</script>