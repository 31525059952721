var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('v-data-table',{staticClass:"mt-16 elevation-4",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-toolbar-title',[_c('h1',{staticClass:"white--text"},[_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"x-large":"","dense":"","color":"white"}},[_vm._v("mdi-package")])],1),_c('span',[_vm._v("Pacotes")])])]),_c('v-spacer'),_c('v-speed-dial',{attrs:{"open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"#948F8F"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","elevation":"0","dark":"","small":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Opções")])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}})],1)]},proxy:true},{key:"item.hours_spent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hours_spent == 0 ? '-' : item.hours_spent)+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount == 0 ? '-' : item.discount)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewPackage(item)}}})]}}])})],1),_c('Package',{ref:"Package",attrs:{"selectedPackage":_vm.selectedPackage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }