var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":false,"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"sticky-toolbar",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.myInvoice.title))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":_vm.downloadInvoice}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-download ")])],1),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"form_invoice",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{staticClass:"mb-16 mt-12",on:{"submit":function($event){$event.preventDefault();return _vm.addInvoice.apply(null, arguments)}}},[_c('fieldset',{attrs:{"disabled":true}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"title","name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Titulo"},model:{value:(_vm.myInvoice.title),callback:function ($$v) {_vm.$set(_vm.myInvoice, "title", $$v)},expression:"myInvoice.title"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"text-h6 text-right"},[_vm._v("Estado:")]),(_vm.myInvoice.paid)?_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"green","text":"Paga","top":""}},[_vm._v(" Pago "),_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1):_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"red","text":"Por pagar","top":""}},[_vm._v(" Por pagar "),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle-outline")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"description","name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"input-7-1","error-messages":errors,"label":"Descriçao"},model:{value:(_vm.myInvoice.description),callback:function ($$v) {_vm.$set(_vm.myInvoice, "description", $$v)},expression:"myInvoice.description"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"total","name":"total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Total","prepend-icon":"mdi-currency-eur"},model:{value:(_vm.myInvoice.total),callback:function ($$v) {_vm.$set(_vm.myInvoice, "total", $$v)},expression:"myInvoice.total"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"expires_at","name":"expires_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Expira a"},model:{value:(_vm.myInvoice.expires_at),callback:function ($$v) {_vm.$set(_vm.myInvoice, "expires_at", $$v)},expression:"myInvoice.expires_at"}})]}}],null,true)})],1)],1)],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }