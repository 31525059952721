import BaseApi from "./BaseApi";

export default class Invoices extends BaseApi{
    construct() {}
    
    static async getInvoices(params, tenant){
        let url = Invoices.getFullApiUrl(tenant) + 'invoices'

        return await Invoices.getAxiosInstance().get(url, {params:params});
    }

    static async download(id, tenant) {
        let url = Invoices.getFullApiUrl(tenant) + 'invoices/download/' + id
        
        return await Invoices.getAxiosInstanceDownload(url)
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/";
    }
}