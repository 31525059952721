import BaseApi from "./BaseApi";

export default class Packages extends BaseApi{
    construct() {}
    
    static async getPackages(params, tenant){
        let url = Packages.getFullApiUrl(tenant) + 'packages'

        return await Packages.getAxiosInstance().get(url, {params:params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/clientusers/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/clientusers/";
    }
}