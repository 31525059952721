<template>
  <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <span>Bem-vindo {{ name }}</span>
      </h1>
      <v-alert
        icon="mdi-shield-lock-outline"
        prominent
        text
        type="info"
      >
        Verifique as suas estatísticas e aceda de forma rápida e intuitiva aos seus dados
      </v-alert>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <Card title="Users" :subTitle="data != null ? data.TotalUsers : 0" description="registados" icon="mdi-account-multiple"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="Pendentes" :subTitle="data != null ? data.TotalInvoices : 0" description="faturas" icon="mdi-briefcase" avatarColor="secondary"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="A expirar" :subTitle="data != null ? data.InvoicesPassed : 0" description="faturas" icon="mdi-receipt-text" avatarColor="warning"/>
          </v-col>
          <v-col cols="12" md="3" v-if="false">
            <Card title="Totais em dívida" :subTitle="data != null ? $currency(data.InvoicesDebt) : 0" description="euros" icon="mdi-currency-eur" avatarColor="#948F8F"/>
          </v-col>
          <v-col cols="12" md="3">
            <Card title="Projetos" :subTitle="data != null ? data.TotalProjects : 0" description="projetos" icon="mdi-briefcase"/>
          </v-col>
        </v-row>
        <v-row class="mt-n12">
          <v-col>
            <InvoicesListings :tenant="getDomain"/>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>
<script>
import Dashboard from "@/api/Dashboard.js"
import Card from '@/components/ui/Card.vue';
import InvoicesListings from '@/components/invoices/InvoicesListings.vue';

export default{
  components:{
    Card,
    InvoicesListings
  },
  data(){
    return{
      data: null,
      name: ''
    }
  },
  beforeCreate(){
    Dashboard.get()
    .then((data) => {
      this.data = data.data
    }).catch(() => {

    })
  },
  mounted(){
    this.name = this.$root.session.name
  },
  computed: {
    getDomain(){
        return this.$session.get('domain')
    },
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
      ];
    }
  }
}
</script>