import BaseApi from "./BaseApi";

export default class Projects extends BaseApi{
    construct() {}
    
    static async getProjects(params, tenant){
        let url = Projects.getFullApiUrl(tenant) + 'projects'

        return await Projects.getAxiosInstance().get(url, {params:params});
    }

    static async list(tenant){
        let url = Projects.getFullApiUrl(tenant) + 'projects/list'

        return await Projects.getAxiosInstance().get(url);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/clientusers/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/clientusers/";
    }
}