import BaseApi from "./BaseApi";

export default class Ticket extends BaseApi{
    construct() {}
    
    static async create(params){
        let url = Ticket.getApiUrl()

        return await Ticket.getAxiosInstance().post(url, params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/ticket/";
    }
}