<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            :fullscreen="false"
            persistent
            max-width="1000px"
        >            
            <v-card>
                <v-toolbar
                    class="sticky-toolbar"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>{{ myProject.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_project" v-slot="{  }">      
                    <v-form class="mb-16 mt-12" @submit.prevent="addProject" >
                        <fieldset :disabled="true">                      
                        <v-row>
                            <v-col cols="12">
                                <label>Programadores envolvidos:</label>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-n4">
                            <v-col cols="12">
                                <v-chip
                                v-for="devOP in myProject.developers_object" :key="devOP.email"
                                class="ma-2"
                                color="#948F8F"
                                text-color="white"
                                >
                                <v-avatar left>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-avatar>
                                {{ devOP.name }}
                                </v-chip>
                            </v-col>
                        </v-row> 
                        <v-row>
                            <v-col cols="12" md="3">
                                <validation-provider v-slot="{ errors }" vid="name" name="name" rules="required">
                                    <v-text-field
                                    v-model="myProject.name"
                                    :error-messages="errors"
                                    label="Nome"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>                                                        
                            <v-col cols="12" md="3">                                
                                <validation-provider v-slot="{ errors }" vid="started_at" name="started_at" rules="required">                                    
                                    <v-text-field
                                        v-model="myProject.started_at"
                                    :error-messages="errors"
                                        label="Inicio"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>                               
                            <v-col cols="12" md="3">                                
                                <validation-provider v-slot="{ errors }" vid="project_deadline" name="project_deadline" rules="required">                                
                                    <v-text-field
                                        v-model="myProject.project_deadline"
                                        :error-messages="errors"
                                        label="Deadline"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>                             
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="myProject.hours_spent"
                                    label="Horas gastas"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-8">
                            <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                <v-textarea
                                name="input-7-1"
                                v-model="myProject.description"
                                :error-messages="errors"
                                label="Descriçao"
                                ></v-textarea>
                            </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-col cols="12" md="3">     
                                <v-row>
                                    <v-col>
                                        <label>Percentagem concluída:</label>
                                        <v-progress-circular                                    
                                            class="mt-4"
                                            :rotate="-90"
                                            :size="150"
                                            :width="25"
                                            :value="myProject.done_percentage"
                                            color="primary"
                                        >
                                            {{ myProject.done_percentage }}% done
                                        </v-progress-circular>      
                                    </v-col>
                                </v-row>        
                            </v-col>
                            <v-col cols="12" md="3">     
                                <v-row>
                                    <v-col>
                                        <label>Dias em falta para final:</label>
                                        <v-progress-circular                                    
                                            class="mt-4"
                                            :rotate="-90"
                                            :size="150"
                                            :width="25"
                                            :value="deadlineDays(myProject)"
                                            color="primary lighten-3"
                                        >
                                            {{ deadlineDays(myProject) }} dias
                                        </v-progress-circular>      
                                    </v-col>
                                </v-row>        
                            </v-col>
                            <v-col cols="12" md="6">     
                                <v-row>
                                    <v-col>
                                        <label>Total membros equipa:</label>
                                        <p class="mt-2" v-if="myProject.developers != null" >
                                            <v-icon v-for="n in myProject.developers.length" :key="n"
                                            x-large color="primary" class="p-6">mdi-human-male </v-icon>
                                        </p>
                                        <p class="text-center primary--text text-h6">                                            
                                            <label>{{ myProject.developers.length }} programadores</label>
                                        </p>
                                    </v-col>
                                </v-row>        
                            </v-col>
                        </v-row>
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
            <v-card>
                <v-toolbar
                    dark
                    color="#948F8F"
                >                    
                    <v-toolbar-title>Comentários: </v-toolbar-title>                    
                </v-toolbar>
                <v-container v-if="myProject.comments != null" >
                    <p class="text-center" v-if="myProject.comments.length == 0">Sem comentários</p>
                    <div :key="reloadComments">
                    <v-timeline dense v-for="c in myProject.comments" :key="c.id">
                        <v-timeline-item size="large" v-if="c.landlord_user == null">
                            <template v-slot:icon>
                                <v-avatar><v-icon color="white">mdi-account</v-icon></v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span>{{ c.tenant_user.name }}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>{{formatData(c.created_at)}}</v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item size="large" v-else>
                            <template v-slot:icon>
                                <v-avatar>
                                    <v-icon color="white" v-if="c.burned_hours == null">mdi-account</v-icon>
                                    <v-icon color="white" v-else>mdi-fire-circle</v-icon>
                                </v-avatar>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>
                                    <v-row v-if="c.burned_hours != null">
                                        <v-col>
                                            <p class="font-weight-bold">Horas queimadas: {{c.burned_hours}}</p>
                                        </v-col>
                                    </v-row>
                                    <strong>{{ c.landlord_user.name }}</strong> at {{formatData(c.created_at)}}
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </div>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<style>
.sticky-toolbar{
    position: sticky; 
    top: 0; 
    z-index: 999;
}
</style>
<script>

export default {
    props:{
        selectedProject:{
            default: null
        }
    },
    data(){
        return{
            developers: [],
            reloadComments: 0,
            commentObject: {
                comment: '',
                burned_hours: '',
                isBurnHours: false,
                id_package: null
            },
            dialog: false,
            loading: false,         
            success: false, 
            myProject:{
                id: null,
                name: '',
                description: '',
                started_at: null,
                project_deadline: null,
                hours_spent: null,
                comments: null,
                developers: null,
                done_percentage: null,
                developers_object: null
            },
        }
    },
    beforeCreate(){
        
    },
    watch:{
        selectedProject:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                this.myProject.name = val.name
                this.myProject.description = val.description
                this.myProject.started_at = (val.started_at != '' || val.started_at != null) ? val.started_at.split(' ')[0] : null
                this.myProject.project_deadline = val.project_deadline != null ? val.project_deadline.split(' ')[0] : null
                this.myProject.hours_spent = val.hours_spent
                this.myProject.id = val.id
                this.myProject.comments = val.comments
                this.myProject.developers = val.developers
                this.myProject.done_percentage = val.done_percentage
                this.myProject.developers_object = val.developers_object
            }
        }
    },
    methods:{
        deadlineDays(ob){
            const date1 = new Date();
            const date2 = new Date(ob.project_deadline);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            return diffDays <= 0 ? 0 : diffDays 
        },
        startedAtChanged(t){
            this.myProject.started_at = t
        },
        projectDeadlinChanged(t){
            this.myProject.project_deadline = t
        },
        formatData(d){
            return d.split('T')[0] + ' ' + d.split('T')[1].split('.')[0]
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadProjects')
        },
        openDialog(){
            this.dialog = true
        },
    }
}
</script>