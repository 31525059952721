import BaseApi from "./BaseApi";

export default class User extends BaseApi{
    construct() {}
    
    static async login(username, password) {       
        
        return User.startRequest().then(() => {
            let url = BaseApi.getApiUrl() + "login";
            
            let data = {
                username: username,
                password: password,
                place: 'atlas',
                api: 'normal'
            }
            
            return User.getAxiosInstance().post(url, data);
        });
    }

    static async reset(email) {
        let url = User.getApiUrl() + "email/reset";

        return User.getAxiosInstance().post(url, {email: email});
    }
    

    // Microsoft login
    static async microsoftLogin(username, oid) {
        return User.startRequest().then(() => {
            let url = BaseApi.getApiUrl() + "login";

            let data = {
                username: username,
                password: oid,
                place: 'atlas',
                api: 'microsoft'
            }

            return User.getAxiosInstance().post(url, data);
        });
    }

    static async registerExternalLogin(username, oid, platform, id) {
        let url = User.getApiUrl() + "register/" + platform + "/" + id;

        let data = {
            username: username,
            password: oid,
            place: 'atlas',
            api: platform
        }

        return User.getAxiosInstance().post(url, data);
    }

    static async find(id) {
        return await User.getAxiosInstance().get(User.getApiUrl() + id);
    }


    static getInstance(data) {
        let user = new User(data);
        
        Object.assign(user, data);

        user.permissions = user.permissions.map((item) => {
            return item.name;
        });

        user.roles = user.roles.map((item) => {
            return item.name;
        });

        return user;
    }

    static async getSession() {
        let url = User.getApiUrl() + "session";

        return User.getAxiosInstance().get(url);
    }

    hasPermission(permissions = []) {

        return this.permissions.some(permission => permissions.indexOf(permission) >= 0);
    }
    
    static async getUsers(){
        let url = User.getFullApiUrl();

        return User.getAxiosInstance().get(url)
    }
    static async logout() {
        let url = BaseApi.getApiUrl() + "logout";

        return User.getAxiosInstance().post(url);
    }

    static async registerNew(params){
        return await User.getAxiosInstance().post(User.getFullApiUrl(),params);
    }

    static async confirmEmail(params) {
        return User.startRequest().then(() => {

            let url = User.getApiUrl() + "verify-email";

            return User.getAxiosInstance().post(url, params);
        });
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/users/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/users/";
    }
}