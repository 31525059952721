<template>
  <v-card class="elevation-10" :key="reload">
    <v-card-text>
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-form @submit.prevent="submit"  class="mb-16">
          <v-progress-linear v-if="loading"
              indeterminate
              class="global-loader"
            ></v-progress-linear>
          <fieldset :disabled="loading">
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                  <v-text-field
                    v-model="fields.name"
                    prepend-inner-icon="mdi-account-details"
                    label="Nome *"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="telephone" name="Telefone" rules="required">
                  <v-text-field
                    v-model="fields.telephone"
                    prepend-inner-icon="mdi-phone"
                    label="Telefone *"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                  <v-text-field
                    :disabled="true"
                    v-model="fields.email"
                    prepend-inner-icon="mdi-at"
                    label="Email *"
                    clearable
                    type="email"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
              <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="password" name="Password" :rules="fields.password != null ? 'required|password_size|special_char_1|capital_letter|one_number' : ''">
                  <v-text-field
                    v-model="fields.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    prepend-inner-icon="mdi-form-textbox-password"
                    @click:append="show1 = !show1"
                    label="Password"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group prepend-icon="mdi-compare" label="Tema" v-model="fields.theme" @change="changeTheme" row>
                  <v-radio v-for="theme in $themes" v-bind:key="theme.name" :label="theme.name" :value="theme"></v-radio>
                </v-radio-group>
                <h3></h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="fields.microsoft_identifier == null">
                <v-btn
                  class="mt-4"
                  outlined
                  @click="microsoftLogin"
                >
                <div v-if="!$msal.isAuthenticated()">
                  <v-icon class="mr-2" >mdi-microsoft</v-icon>Registar conta Microsoft
                </div>
                <div v-else>
                  <v-icon class="mr-2">mdi-microsoft</v-icon>Registar {{$msal.data.user.userName}}
                </div>
                </v-btn>
               
              </v-col>
              <v-col cols="12" v-else>
                <v-btn
                  class="mt-4"
                  outlined
                  disabled
                >
                <div>
                  <v-icon class="mr-2" >mdi-microsoft</v-icon>Conta registada
                </div>
                </v-btn>
               
              </v-col>
            </v-row>
            
            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                  Gravar
                </v-btn>

                <v-btn v-if="!!user && !user.is_verified" depressed color="secondary" class="mr-5 ml-5 mb-5" type="button" @click="resendActivationEmail">
                  Reenviar Email de Ativação
                </v-btn>

                <v-btn depressed color="error" class="mr-5 ml-5 mb-5" type="button" @click="reset" v-if="false">
                  Reset Password
                </v-btn>

                
              </v-col>
            </v-row>
          </fieldset>
        </v-form>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Utilizador gravado com sucesso!
        </DialogSuccess>
        <v-dialog
            v-model="password_reseted"
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="h5">Reset Password</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        offset-md="3"
                      >
                      <h2 class="success--text text-center">Email enviado!</h2>
                      <v-row class="mt-6" align="center" justify="space-around">
                        <v-icon
                          large
                          class="text-center"
                          color="success"
                        >
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="password_reseted = false"
                >
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="activation_email_resent"
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="h5">Email de Ativação</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        offset-md="3"
                      >
                      <h2 class="success--text text-center">Email enviado!</h2>
                      <v-row class="mt-6" align="center" justify="space-around">
                        <v-icon
                          large
                          class="text-center"
                          color="success"
                        >
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="activation_email_resent = false"
                >
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>
<script>
import User from "@/api/User.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ClientUsers from "@/api/ClientUsers.js"

export default {
  components: {
    DialogSuccess
  },
  props: {
   user: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
  '$route.params.id'() {
    let parent = this
    User.find(this.$route.params.id)
      .then((r) => {
        parent.fields = r.data
      })
    },
    user: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        data.roles = data.roles.map((item) => { return item.id});

        this.fields = data;
        
        console.log(this.fields)

        this.fields.id = data.id;

        this.fields.theme = this.$themes.find((item) => data.theme == null ? false : item.name == data.theme.name)

        if(this.fields.theme == undefined || this.fields.theme.length == 0){
          this.fields.theme = this.$themes[0];
        }
      }
    },
  },
  data: () => ({
    reload: 0,
    clients: [],
    show1: false,
    collaborators: [],
    fields: {
      id: null,
      name: null,
      email:null,
      telephone:null,
      is_active:0,
      has_2fa: null,
      two_fa_code: null,
      roles:[],
      theme: null,
      password: null,
      collaborator: null,
      clients: [],
      microsoft_identifier: null
    },
    qrcode:null,
    loading:false,
    error: {
      title: '',
      message: '',
    },
    roles: [],
    password_reseted:false,
    activation_email_resent:false,
    success: false,
  }),
  async mounted(){
    this.fillBaseData();
    this.method = "update"
    if(this.fields.id == this.$root.session.id)
      this.microsoftRegister = true
      
    
  },
  methods:{
    microsoftLogin(){
      if(this.$msal.isAuthenticated()){
        if(this.$msal.data.user.userName == this.$root.session.email){
          User.registerExternalLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid, 'microsoft', this.$root.session.id)
            .then(() => {

              this.$cookies.remove('addMicrosoftAccount');
              this.fields.microsoft_identifier = 1
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);
                
                return;
              }
          });
        }
      }else this.$msal.signIn()
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/internal_management/users');
    },
    changeTheme(theme){
      this.setTheme(theme)

      this.fields.theme = theme;
    },
    fillBaseData(){
    },
    toggle2Fa()
    {
      if(this.fields.has_2fa){
          User.enable2FA()
          .then(() => {
            this.render2Fa()
          });

          return;
      }
      
      User.disable2FA()
          .then(() => {
            this.qrcode = null;
          });
    },
    render2Fa(){
      User.get2FA()
        .then(({data}) => {
          this.qrcode = data.svg;
        });
    },
    reset () {
      User.reset(this.user.email)
          .then(() => {

              this.password_reseted = true;
          });
    },
    resendActivationEmail() {
      User.resendActivationEmail(this.user.id)
        .then(() => {

            this.activation_email_resent = true;
        });
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.password != null)
          this.fields.password = this.$encryptValue(this.fields.password)

        ClientUsers[this.method](this.fields, this.fields.id).then(({data}) => {

          this.success = true;

          this.loading = false;

          if(data.id == this.$root.session.id){
            let user = User.getInstance(data);

            this.$root.session = user;

            return;
          }

          this.setTheme(this.$root.session.theme);
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
