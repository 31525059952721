<template>
    <section class="mt-16">
        <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      >
      
    </v-breadcrumbs>
      <ProjectsListing class="mt-n10" :tenant="getDomain"/>
      </section>
</template>
<script>
    
import ProjectsListing from '@/components/projects/ProjectsListing.vue';

export default{
    components: {
        ProjectsListing
    },
    computed:{
        getDomain(){
            return this.$session.get('domain')
        },
        crumbs: function() {
            return [
                {
                    text: 'Home',
                    icon: 'mdi-account',
                    disabled: false,
                    to: 'client',
                    exact: true,
                },
                {
                    text: 'Projetos',
                    disabled: false,
                    to: 'projects',
                    exact: true,
                },
            ];
        }
    }
}
</script>