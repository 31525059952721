import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Layout from '../Layout.vue'
import Dashboard from '../views/Dashboard.vue'
import NotFound from '../views/NotFound.vue'
import Projects from '../views/Projects.vue'
import Packages from '../views/Packages.vue'
import Invoices from '../views/Invoices.vue'
import UpdateUser from '../views/settings/users/UpdateUser.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/verify-email/:id/:hash',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/client',
        component: Layout,
        children: [
            {
                path: '/:tenant/client',
                component: Dashboard
            },
            {
                path: '/:tenant/projects',
                component: Projects
            },
            {
                path: '/:tenant/packages',
                component: Packages
            },
            {
                path: '/:tenant/invoices',
                component: Invoices
            },
            {
                path: '/:tenant/user-profile/*',
                component: UpdateUser
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
