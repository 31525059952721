<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            :fullscreen="false"
            persistent
            max-width="1000px"
        >        
        <v-card>
                <v-toolbar
                    class="sticky-toolbar"
                    dark
                    color="primary"
                    >                    
                    <v-toolbar-title>Criar novo ticket</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_ticket" v-slot="{ invalid }">      
                        <v-form class="mb-16 mt-12" @submit.prevent="addTicket">
                            <v-row>               
                                <v-col cols="12" md="12">
                                    <v-select
                                        label="Relacionar projeto"
                                        :items="computedProjects"
                                        item-text="name"
                                        item-value="id"
                                        v-model="ticket.id_project"
                                    ></v-select>
                                </v-col>    
                            </v-row>
                            <v-row>               
                                <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="title" name="title" rules="required">
                                        <v-text-field :error-messages="errors" label="Titulo" v-model="ticket.title"></v-text-field>
                                    </validation-provider>
                                </v-col>    
                            </v-row>
                            <v-row>               
                                <v-col cols="12" md="12">
                                    <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                        <v-textarea
                                        name="input-7-1"
                                        v-model="ticket.description"
                                        :error-messages="errors"
                                        label="Descriçao"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>    
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-file-input
                                        multiple
                                        label="Anexar ficheiros"
                                        v-model="ticket.files"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Gravar
                                </v-btn>
                            </v-col>
                            </v-row>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Ticket enviado com sucesso. Aguarde até os nossos colaboradores abrirem o seu pedido
        </DialogSuccess>
    </v-row>
</template>
<script>
import Ticket from "@/api/Ticket.js"
import Projects from "@/api/Projects.js"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
    components:{
        DialogSuccess
    },
    data(){
        return{
            success: false,
            dialog: false,
            projects: [],
            ticket: {
                title: '',
                description: '',
                files: '',
                id_project: null
            }
        }
    },
    watch:{
        tenant(){
            this.loadProjects()
        }
    },
    mounted(){
        this.loadProjects()
    },
    methods:{
        async loadProjects(){
            await Projects.list(this.tenant).then(response => {
                this.projects = response.data       

                this.loading = false;

            });
        },
        onSuccess(){
            this.ticket = {
                title: '',
                description: '',
                files: '',
                id_project: null
            }
            this.success = false
        },
        addTicket(){
            const formData = new FormData()
            for (var i = 0; i < this.ticket.files.length; i++ ){
                let file = this.ticket.files[i];
                formData.append('files[]', file);
            }

      
            
            formData.append('title', this.ticket.title)
            formData.append('description', this.ticket.description)
            formData.append('id_project', this.ticket.id_project)
            formData.append('total_files', this.ticket.files.length)

            Ticket.create(formData).then(() => {
                this.success = true;

                this.loading = false;
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        openDialog(){
            this.dialog = true
        }
    },
    computed:{
        computedProjects(){
            return this.projects
        }
    }
}
</script>