<template>
    <section class="mt-16">
        <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      >
      
    </v-breadcrumbs>
      <PackagesListings class="mt-n10" :tenant="getDomain"/>
      </section>
</template>
<script>
import PackagesListings from '@/components/packages/PackagesListings.vue';
    

export default{
    components:{
        PackagesListings
    },
    computed:{
        getDomain(){
            return this.$session.get('domain')
        },
        crumbs: function() {
            return [
                {
                    text: 'Home',
                    icon: 'mdi-account',
                    disabled: false,
                    to: 'client',
                    exact: true,
                },
                {
                    text: 'Pacotes',
                    disabled: false,
                    to: 'packages',
                    exact: true,
                },
            ];
        }
    }
}
</script>