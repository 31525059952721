import BaseApi from "./BaseApi";

export default class ClientUsers extends BaseApi{
    construct() {}

    static async update(params, id) {
        return await ClientUsers.getAxiosInstance().put(ClientUsers.getFullApiUrl() + id,params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/clientusers/";
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/admin/clientusers/";
    }
}